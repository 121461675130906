import { useSelector } from 'react-redux';
import MaterialsLevel from '../../../components/MaterialsLevel/MaterialsLevel'
import { useEffect, useState } from 'react';
import { fetchMaterials } from '../../../helpers/materials/fetchMaterials';
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled';

function Materials() {
   const { data } = useSelector((state) => state.auth);
   const [loading, setLoading] = useState(false);
   const [materials, setMaterials] = useState([]);
 
   useEffect(() => {
    fetchMaterials(setLoading, data.ageGroup, data.englishLevel, data.accessType, setMaterials)
   }, [data.ageGroup, data.englishLevel, data.accessType]);
   console.log(materials)
   return (
     <PagesWrapper>
         <MaterialsLevel loading={loading} topTitle={'Матеріали'} materials={materials} englishLevel={data.englishLevel} isStudent/>     
     </PagesWrapper>
   );
}
 
 export default Materials;