import styled from "styled-components";


export const Wrapper = styled.div`
   display: flex;
   align-items: center;
   padding: 13px 0px 0px 0px;
   margin-bottom: ${props => props.notAdmin ? "50px" : "100px"} ;
    flex-direction: column;
   @media (max-width: 768px) {
     
     
      margin-bottom: 150px;
   };
   @media (min-width: 768px) {
      padding: 20px 20px 0px;
   }
`

export const PageTitle = styled.p`
   font-family: Raleway;
   font-size: 22px;
   font-weight: 400;
   line-height: 24.2px;
   margin-top: 20px;
`
export const TopWrapper = styled.div` 
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding-right: 15px;

`
