import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import * as styled from "./TeacherSalary.styled";
import { useState, useEffect } from "react";
import DateSalaryPicker from "../../../components/DatePicker/DatePicker";
import { fetchSalaryData } from "../../../helpers/teacherSalary/fetchSalaryData";
import { getAllTeachers } from "../../../helpers/getAllTeachers.js";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled.jsx";

function TeacherSalary({adminInfo}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [totalTeacherSalary, setTotalTeacherSalary] = useState(0);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState('')

  const getSalaryDate = (id) => {
    setSelectedTeacher(id)
    fetchSalaryData(id, startDate, endDate, setTotalTeacherSalary);
  };
  useEffect(() => {
    getAllTeachers().then((data) => {
      const extractedTeachers = data.map((teacher) => ({
        id: teacher._id,
        teacherId: teacher.teacherId,
        name: teacher.name,
        accessType: teacher.accessType
      }));
      setTeachers(extractedTeachers);
    });
  }, []);
  
  return (
    <PagesWrapper>
      <TopPageTitle value={"Виплата вчителям"} />

      <styled.Container>
        <DateSalaryPicker
          withoutBtn
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          fetchSalaryData={getSalaryDate}
        />
        <styled.List>
          {teachers.filter(({accessType}) => accessType === adminInfo.accessType).map(({ id, teacherId, name }) => (
            <styled.ListItem key={id}>
              <styled.TeacherInfo>
                {name} id({teacherId})
              </styled.TeacherInfo>
              <styled.SalaryInfo>
                ЗП за вибраний період: {selectedTeacher === id ? totalTeacherSalary : '0'}
              </styled.SalaryInfo>
              <styled.Button onClick={() => getSalaryDate(id)}>
                Отримати зарплатний звіт
              </styled.Button>
            </styled.ListItem>
          ))}
        </styled.List>
      </styled.Container>
    </PagesWrapper>
  );
}

export default TeacherSalary;
