import * as styled from "./AddStudent.styled.jsx";
import { useForm } from "react-hook-form";
import { firstStepFields, secondStepFields, secondStepFieldsDE, thirdStepFields} from "../../../helpers/studentFields.js";
import axios from "../../../axios";
import { useEffect, useState } from "react";
import { CommonBtn } from "../../../styles/components/Button.styled.jsx";
import CurrentStep from "../../../components/CurrentStep/CurrentStep.jsx";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle.jsx";
import FirstStep from "../../../components/AddStudentForm/FirstStep/FirstStep.jsx";
import SecondStep from "../../../components/AddStudentForm/SecondStep/SecondStep.jsx";
import ThirdStep from "../../../components/AddStudentForm/ThirdStep/ThirdStep.jsx";
import AddedSuccessfully from "../../../components/AddedSuccessfully/AddedSuccessfully.jsx";
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled'
import { useAdmin } from "../../../context/AdminInfo.js";

function AddStudent() {
  const [successAdded, setSuccessAdded] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [teacherId, setTeacherId] = useState('');
  const [teacherTrialLesson, setTeacherTrialLesson] = useState('');
  const { adminInfo } = useAdmin();

  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({
    defaultValues: {
      name: "",
      studentId: "",
      ageGroup: "",
      classSchedule: [],
      lessonPackage: "",
      phoneNumber: "",
      teacherName: "",
      englishLevel: "",
      lessonPrice: "",
      email: "",
      password: "",
      accessType: '',
      userType: "student",
    },
    mode: "onChange",
  });
  const addStudentToTeacher = async (data) => {
    const { name, ageGroup, _id, classSchedule, englishLevel, studentId, accessType } = data;
    try {
      await axios.post(`teachers/${teacherId}/students`, { name, ageGroup, _id, classSchedule, englishLevel, studentId, accessType });
    } catch (error) {
      console.error("Error adding student to teacher:", error);
    }
  };
  const markTrialLesson = async () => {
    try {
      await axios.post(`trial-lesson/mark`, { teacherId: teacherTrialLesson, salary: '100', lessonType: 'Trial'});
    } catch (error) {
      console.error("Error mark trial lesson:", error);
    }
  }
  const onSubmit = async (data) => {
    setCurrentStep(prev => prev + 1);
    try {
      data.teacherId = teacherId;
      const response = await axios.post("students", data); 
      const newStudentId = response.data._id; 
      await addStudentToTeacher({ ...data, _id: newStudentId });
      await markTrialLesson()
      setSuccessAdded(true);
      reset();
    } catch (error) {
      setSuccessAdded(false);
      console.error("Error adding student:", error);
    }
  };
  
  useEffect(() => {
    if (adminInfo && adminInfo.accessType) {
      setValue("accessType", adminInfo.accessType);
    }
  }, [adminInfo, setValue]);

  if (!adminInfo) {
    return <div>Loading...</div>;
  }
  return (
    <PagesWrapper>
      <styled.FormWrapper>
        <TopPageTitle value={'Додати учня'}/>
       {currentStep !== 4 && <CurrentStep currentStep={currentStep}/>}

        <styled.Form onSubmit={handleSubmit(onSubmit)}>

          {currentStep === 1 && <FirstStep setTeacherId={setTeacherId} teacherTrialLesson={teacherTrialLesson} setTeacherTrialLesson={setTeacherTrialLesson} firstStepFields={firstStepFields} register={register} setFormValue={setValue} errors={errors}/>}
          {currentStep === 2 && <SecondStep secondStepFields={adminInfo.accessType !== 'en' ? secondStepFieldsDE : secondStepFields} register={register} setFormValue={setValue} errors={errors}/>}
          {currentStep === 3 && <ThirdStep thirdStepFields={thirdStepFields} register={register} setFormValue={setValue} errors={errors}/>}
          {currentStep === 4 && <AddedSuccessfully isSuccess={successAdded} successTitle={'Учня успішно додано'} errorTitle={'Перевірте чи заповнили ви всі розділи'}/>}
          <styled.ButtonWrapper isFirstStep={currentStep === 1}> 
            {(currentStep !== 1 && currentStep !== 4) && <CommonBtn className="back-btn" onClick={() => setCurrentStep(prev => prev - 1)}>Назад</CommonBtn>}
            {currentStep < 3 && <CommonBtn className="next-btn" onClick={() => setCurrentStep(prev => prev + 1)}>Далі</CommonBtn>}
            {currentStep === 3 && <CommonBtn type="submit">Додати</CommonBtn>}
          </styled.ButtonWrapper>
        
        </styled.Form>
        
        
        
      </styled.FormWrapper>
    </PagesWrapper>
  );
}

export default AddStudent;
