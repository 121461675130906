import { useParams } from 'react-router-dom';
import MaterialsLevel from '../../../components/MaterialsLevel/MaterialsLevel';
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled';
import { useEffect, useState } from 'react';
import { fetchMaterials } from '../../../helpers/materials/fetchMaterials';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAuthMe } from '../../../redux/slices/auth';

function MaterialsGroupLevel({ isAdmin }) {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.auth); 
  const [loading, setLoading] = useState(false);
  const [materials, setMaterials] = useState([]);
  const { group, level } = useParams();
  const [addedLesson, setAddedLesson] = useState(false);

  useEffect(() => {
    if (!data && status === 'loading') {
      dispatch(fetchAuthMe());
    }
  }, [dispatch, data, status]);


  useEffect(() => {
    if (!data || status !== 'success') return; 

    const fetchData = async () => {
      setLoading(true);
      await fetchMaterials(setLoading, group, level, data.accessType, setMaterials, setAddedLesson);
      setLoading(false);
    };

    fetchData();
  }, [group, level, addedLesson, data, status]);

  

  return (
    <PagesWrapper>
      <MaterialsLevel
        loading={loading}
        topTitle={'Матеріали'}
        materials={materials}
        isTeacher={data?.userType === 'teacher'}
        isAdmin={isAdmin}
        setAddedLesson={setAddedLesson}
      />
    </PagesWrapper>
  );
}

export default MaterialsGroupLevel;
