import axios from "../../axios";

export const getByGroupAndLevel = async(group, level, accessType) => {
   const {data} = await axios.get(`materials/group/${group}/level/${level}/${accessType}`)

   return data
}

export const materialGroups = [
   { name: "Діти", value: "kids" },
   { name: "Підлітки", value: "teenagers" },
   { name: "Дорослі", value: "adults" },
 ];