import { useParams } from "react-router-dom";
import * as styled from "./AddMaterial.styled"
import axios from "../../axios";
import { useEffect, useState } from "react";
import { useAdmin } from "../../context/AdminInfo";

function AddMaterial({noMaterials, topicName, setAddedLesson, setIsOpen, newTopic}){
   const {group, level} = useParams()
   const [lessonName, setLessonName] = useState('')
   const [lessonLink, setLessonLink] = useState('')
   const [lessonAnswers, setLessonAnswers] = useState('')
   const [isRevision, setIsRevision] = useState(false)
   const { adminInfo } = useAdmin();
   const topics = [{
      name: topicName ? topicName : 'Topic 1',
      lessons: [
         {
            name: lessonName,
            type: !isRevision? 'Lesson' : "Revision",
            url: lessonLink,
            answers: lessonAnswers.length > 0 ? lessonAnswers : ""
         },
      ],
      
   }]
   

   const addMaterial =  () => {
      axios.post(`materials`, {group, level, topics: topics , accessType: adminInfo.accessType,})
      .then(() => {
         setLessonName('')
         setLessonLink('')
         setIsRevision(false)
         setAddedLesson(true)
         setIsOpen(false)
      })
      .catch(err => {
         console.error(err)
      })
   }
   
  return (
    <styled.Wrapper>
      <styled.InputWrapper>
         <styled.InputName>Додати урок? Вставте посилання *</styled.InputName>
         <styled.Input type="text" placeholder="Вставте посилання на презентацію" onChange={(e) => setLessonLink(e.target.value)}/>
      </styled.InputWrapper>

      <styled.InputWrapper>
         <styled.InputName>Назва уроку *</styled.InputName>
         <styled.Input type="text" placeholder="Впишіть назву уроку, наприклад: 'Lesson 1'" onChange={(e) => setLessonName(e.target.value)}/>
      </styled.InputWrapper>

      <styled.InputWrapper>
         <styled.InputName>Додати відповіді до уроку?</styled.InputName>
         <styled.Input type="text" placeholder="Вставте посилання на Google документ" onChange={(e) => setLessonAnswers(e.target.value)}/>
      </styled.InputWrapper>
      
      <styled.RadioButtonWrapper checked={isRevision} onClick={() => setIsRevision(prev => !prev)}>
          <styled.InputName>Позначити як Revision?</styled.InputName>
          <styled.RadioButton value={isRevision}/>
      </styled.RadioButtonWrapper>
     
      <styled.Btn onClick={() => addMaterial()}>Додати</styled.Btn>
    </styled.Wrapper>
  )
};

export default AddMaterial;
