import * as styled from "./Login.styled.jsx";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAuth, fetchAuthMe } from "../../redux/slices/auth.js";
import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import logo from "../../assets/icons/logo-flags.svg";
import Loader from "../../components/Loader/Loader";
function Login() {
  const [userType, setUserType] = useState(null);
  const [notAllowed, setNotAllowed] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (values) => {
    setLoading(true);

    await dispatch(fetchAuth(values))
      .then((data) => {
        window.localStorage.setItem("token", data.payload.token);
        setUserType(data.payload.userType);
      })
      .catch((err) => {
        setNotAllowed(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (userType) {
      navigate(`/${userType}/general`, { state: { fromLogin: true } });
    }
  }, [navigate, userType]);

  useEffect(() => {
    const isNavigate = async () => {
      
      const data = await dispatch(fetchAuthMe());

      if (data?.payload?.userType) {
        navigate(`/${data.payload.userType}/general`, { state: { fromLogin: true } });
        
      } else {
        navigate(`/`);
        
      }
    };
    isNavigate();
  }, [dispatch, navigate]);
  return (
    <styled.Wrapper>
      <ReactSVG src={logo} />
      <styled.FormWrapper>
        <styled.Title>Вхід в особистий кабінет</styled.Title>
        <styled.Form onSubmit={handleSubmit(onSubmit)}>
          <styled.InputWrapper>
            <styled.InputName>Логін</styled.InputName>
            <styled.Input
              onFocus={() => {
                if(!notAllowed) return
                setNotAllowed(false)
              }}
              placeholder="логін"
              {...register("email", { required: "Please, write the email" })}
            />
            <styled.ErrorMessage>
              {errors.email
                ? "Будь ласка, впишіть логін який надав вам менеджер"
                : ""}
            </styled.ErrorMessage>
          </styled.InputWrapper>
          <styled.InputWrapper>
            <styled.InputName>Пароль</styled.InputName>
            <styled.Input
              onFocus={() => {
                if(!notAllowed) return
                setNotAllowed(false)
              }}
              placeholder="пароль"
              {...register("password", {
                required: "Please, write the password",
              })}
            />
            <styled.ErrorMessage>
              {errors.password
                ? "Будь ласка, впишіть пароль який надав вам менеджер"
                : ""}
            </styled.ErrorMessage>
          </styled.InputWrapper>
          {!loading && notAllowed && (
            <styled.ErrorMessage notAllowed={notAllowed}>
              Не правильний логін або пароль
            </styled.ErrorMessage>
          )} 
         {!loading ? (
            <styled.Btn type="submit" size="large" variant="contained">
              Увійти
            </styled.Btn>
          ) : (
            <Loader customWidth={150}/>
          )}
        </styled.Form>
      </styled.FormWrapper>
    </styled.Wrapper>
  );
}

export default Login;
