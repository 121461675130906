import styled from "styled-components";

export const AvatarWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 20px;
`
export const HelloTitle = styled.p`
   font-family: Raleway;
   font-size: 15px;
   font-weight: 300;
   line-height: 20.5px;
   
`

export const UserName = styled.p`
   font-family: Raleway;
   font-size: 20px;
   font-weight: 600;
   color: #3D3D3D;
   margin-left: 5px;
   margin-top: 30px;
`
export const UserNameWrapper = styled.div`
   display: flex;
   align-items: flex-end;
`