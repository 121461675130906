import styled from "styled-components";

export const MaterialsWrapper = styled.div`
   
`

export const TopicWrapper = styled.div`
   &:not(:last-child){
      margin-bottom: 60px;
   };
`

export const TopicName = styled.p`
   font-family: Raleway;
   font-size: 18px;
   font-weight: 700;
   line-height: 27.5px;
   margin-bottom: 20px;
   @media (min-width: 768px){
      font-size: 25px;
   }
`

export const List = styled.ul`
   display: flex;
   flex-wrap: wrap;
   gap: 15px;
   @media (min-width: 1280px) {
      gap: 30px;
   }
`

export const Item = styled.li`
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative; 
   padding: 10px;
   box-shadow: 0px 4px 4px 0px #9C9C9C40;
   background-color: #F9F9F9;
   flex-basis: calc(100% / 2 - 15px);
   border-radius: 10px;
   transition: all 0.3s ease;

   &::before {
      content: '';
      position: absolute;
      height: 8px;
      left: 0;
      top: 0;
      width: 100%;
      background-color: ${props => !props.isAlternate ? '#A09EFF' : '#FFC857'};
      border-radius: 10px 10px 0px 0px;
      transition: all 0.3s ease;
   };

   ${props => props.isRevision && `
      &::after {
         content: '';
         position: absolute;
         height: 8px;
         right: 0;
         top: 0;
         width: 100%;
         background: linear-gradient(90deg, #A09EFF 0%, #FFC857 100%);
         border-radius: 10px 10px 0px 0px;
         transition: all 0.3s ease;
         z-index: 10;
      };
   `}

   

   @media (min-width: 768px){
      flex-basis: calc(100% / 3 - 15px);
   };
   @media (min-width: 1280px) {
      flex-basis: calc(100% / 4 - 30px);
      padding: 25px 35px 15px 35px;
      &:hover::before {
       height: 20px; 
      }


      &:hover::after {
         height: 20px; 
      };
   }
`

export const LessonName = styled.a`
   font-family: Raleway;
   font-size: 17px;
   font-weight: 700;
   text-align: center;
   color: #3D3D3D;
   transition: 0.3s;
   width: 100%;
   @media (min-width: 1280px) {
      font-size: 22px;
   }; 
   &:hover{
      color: #A09EFF;
   }
`
export const Answers = styled.a`
   font-family: Raleway;
   font-size: 14px;
   font-weight: 400;
   color: #000000;
   padding-bottom: 2px;
   border-bottom: 1px solid #000000;
   transition: 0.3s;
   margin-top: 5px;
   @media (min-width: 1280px) {
      font-size: 14px;
   };

   &:hover{
      border-bottom: 1px solid #A09EFF;
      color: #A09EFF;
   }

`
export const BtnWrapper = styled.div`
      display: flex;
      flex-direction: column;
`

export const Btn = styled.button`
   font-family: Raleway;
   font-size: 21px;
   font-weight: 700;
   line-height: 18.7px;
   color: rgb(0, 0, 0);
   max-width: 40px;
   border-radius: 25px;
   background: ${props => props.isOpen ? '#FFC857' : '#A09EFF'};
   padding: 10px 15px;
   cursor: pointer;
   transition: 0.3s;
   margin-top: 20px;
`

export const TopicBtn = styled.button`
   font-family: Raleway;
   font-size: 11px;
   font-weight: 700;
   line-height: 18.7px;
   color: rgb(0, 0, 0);
   max-width: 220px;
   border-radius: 15px;
   background: linear-gradient(90deg, rgb(210, 209, 255) 0%, rgb(255, 225, 162) 100%);
   padding: 10px 15px;
   cursor: pointer;
   transition: 0.3s;
   margin-top: 50px;
`