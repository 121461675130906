import { useNavigate, useParams } from "react-router-dom";
import * as styled from "./StudentsGroup.styled";
import { useEffect, useState } from "react";
import { getAgeGroup } from "../../../helpers/getAgeGroup";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";
import {langs} from "../../../helpers/langs"

function StudentsGroup({ adminInfo }) {
  const [students, setStudents] = useState([]);
  const navigate = useNavigate();
  const { value } = useParams();

  useEffect(() => {
    getAgeGroup(value).then((data) => {
      setStudents(data.students);
    });
  }, [value]);

  const studentClickId = (id) => {
    navigate(`${id}`);
  };

  if (!adminInfo) {
    return <p>Loading...</p>;
  }

  const filteredStudents = students.filter(
    ({ accessType }) => accessType === adminInfo.accessType
  );

  return (
    <PagesWrapper>
      <TopPageTitle value={value} />

      <styled.List isAllAdmin={adminInfo.accessType === "all"}>
        {adminInfo.accessType !== "all" && (
          <styled.AddStudentItem onClick={() => navigate(`addstudent`)}>
            <styled.ItemTitle>+ Додати учня</styled.ItemTitle>
          </styled.AddStudentItem>
        )}

        {adminInfo.accessType === "all"
          ? langs.map(({ key, value }) => (
              <styled.Column key={key}>
                <styled.ColumnTitle>{value}</styled.ColumnTitle>
                {students
                  .filter((student) => student.accessType === key)
                  .map(({ _id, studentId, name }) => (
                    <styled.Item key={_id} onClick={() => studentClickId(_id)} isAllAdmin={adminInfo.accessType === "all"}>
                      <styled.ItemTitle>{`${name} - id(${studentId})`}</styled.ItemTitle>
                    </styled.Item>
                  ))}
              </styled.Column>
            ))
          : filteredStudents.map(({ _id, studentId, name }) => (
              <styled.Item key={_id} onClick={() => studentClickId(_id)}>
                <styled.ItemTitle>{`${name} - id(${studentId})`}</styled.ItemTitle>
              </styled.Item>
            ))}
      </styled.List>
    </PagesWrapper>
  );
}

export default StudentsGroup;
