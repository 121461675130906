import { useState, useEffect } from "react";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";
import { getAllStudents } from "../../../helpers/getAllStudents";
import { getAllTeachers } from "../../../helpers/getAllTeachers";
import axios from '../../../axios'
import LessonChangesBlock from "../../../components/LessonChangesBlock/LessonChangesBlock";
import * as styled from './LessonReschedule.styled'

function LessonReschedule({adminInfo}){
   const [students, setStudents] = useState([])
   const [teachers, setTeachers] = useState([])
   const [selectedStudent, setSelectedStudent] = useState('')
   const [selectedTeacher, setSelectedTeacher] = useState('')
   const [selectedDayTime, setSelectedDayTime] = useState({ day: "", time: "" });
   const [selectedDayTimeCancel, setSelectedDayTimeCancel] = useState({ day: "", time: "" });
   const [selectedStudentCancel, setSelectedStudentCancel] = useState('')
   const [selectedTeacherCancel, setSelectedTeacherCancel] = useState('')
   const [message, setMessage] = useState('')
   const [messageCancel, setMessageCancel] = useState('')
   const [reschedules, setReschedules] = useState([])
   useEffect(() => {
      getAllStudents().then(res => setStudents(res.filter(({accessType}) => accessType === adminInfo.accessType))).catch(error => console.log(error))
   },[])
   useEffect(() => {
      getAllTeachers().then(res => setTeachers(res.filter(({accessType}) => accessType === adminInfo.accessType))).catch(error => console.log(error))
   },[])
   const handleSubmit = () => {
      axios.post(`lesson-reschedule/${selectedStudent}/${selectedTeacher}`, { lessonReschedule: selectedDayTime })
      .then(() => {
         setMessage('Урок успішно перенесено')
         setSelectedStudent('')
         setSelectedTeacher('')
         setSelectedDayTime({ day: "", time: "" })
      })
      .catch(() => setMessage('Щось пішло не так'))    
   }
   const cancelSubmit = () => {
      axios.post(`lesson-reschedule/cancel/${selectedStudentCancel}/${selectedTeacherCancel}`, { lessonReschedule: selectedDayTimeCancel })
      .then(() => {
         setMessageCancel('Урок успішно скасовано')
         setSelectedStudentCancel('')
         setSelectedTeacherCancel('')
         setSelectedDayTimeCancel({ day: "", time: "" })
      })
      .catch(() => setMessageCancel('Щось пішло не так'))    
   }
   const getReschedules = () => {
      axios.get('lesson-reschedules/get-all')
       .then(res => setReschedules(res.data))
       .catch(() => setMessageCancel('Щось пішло не так'))    
   }
   const removeReschedule = (id) => {
      axios.delete(`lesson-reschedule/${id}`)
       .then(res => setReschedules(res.data.reschedules))
       .catch(err => console.error(err))
   }
  return (
    <PagesWrapper>
      <TopPageTitle value={"Зміни уроку"} />
      <LessonChangesBlock title={'Заміна/Перенесення уроку'} students={students} setSelectedStudent={setSelectedStudent} selectedStudent={selectedStudent} teachers={teachers} setSelectedTeacher={setSelectedTeacher} selectedTeacher={selectedTeacher} selectedDayTime={selectedDayTime} setSelectedDayTime={setSelectedDayTime} message={message} handleSubmit={handleSubmit} btnText={'Підтвердити заміну/перенесення уроку'}/>

      <LessonChangesBlock title={'Скасування уроку'} students={students} setSelectedStudent={setSelectedStudentCancel} selectedStudent={selectedStudentCancel} teachers={teachers} setSelectedTeacher={setSelectedTeacherCancel} selectedTeacher={selectedTeacherCancel} selectedDayTime={selectedDayTimeCancel} setSelectedDayTime={setSelectedDayTimeCancel} message={messageCancel} handleSubmit={cancelSubmit} btnText={'Підтвердити скасування уроку'}/>
    
      <styled.ReschedulesWrapper>
         <styled.ReschedulesBtn onClick={getReschedules}>Отримати зміни</styled.ReschedulesBtn>
         <styled.List>
            {reschedules.map(({lessonReschedule, studentName, student_id, teacherName, teacherId, type, _id, createdAt}) => {
            const date = new Date(createdAt);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const formattedDate = date.toLocaleDateString('en-US', options); 
            return (
               <styled.Item key={_id}>
                     <styled.Text>Student: {`${studentName}   id(${student_id})`}</styled.Text>
                     <styled.Text>Teacher: {teacherName}</styled.Text>
                     <styled.Text>Lesson reschedule: {lessonReschedule.day}:  {lessonReschedule.time}</styled.Text>
                     <styled.Text>Type: {type}</styled.Text>
                     <styled.Date>
                        <styled.DateTitle>
                         {formattedDate}
                        </styled.DateTitle>
                     </styled.Date>
                     <styled.RemoveBtn onClick={() => removeReschedule(_id)}>Видалити</styled.RemoveBtn>
               </styled.Item>
            )})}
         </styled.List>
      </styled.ReschedulesWrapper>
    </PagesWrapper>
  )
};

export default LessonReschedule;
