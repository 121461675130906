import { useState, useEffect } from "react";
import * as styled from "./StudentInfo.styled.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { getStudentInfo } from "../../../helpers/getStudentInfo.js";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle.jsx";
import axios from "../../../axios";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled.jsx";
import SelectTeacher from "../../../components/SelectTeacher/SelectTeacher.jsx";
import { FaClock, FaTrash } from "react-icons/fa";

function StudentInfo() {
  const [student, setStudent] = useState({});
  const [teacherClick, setTeacherClick] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(null);
  const [editingSchedule, setEditingSchedule] = useState(null);
  const [newDay, setNewDay] = useState(""); // Новое поле для дня
  const [newTime, setNewTime] = useState("");

  const { value, id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getStudentInfo(value, id).then(({ student }) => setStudent(student));
  }, [value, id]);

  const handleRemove = (studentId, teacherId) => {
    axios
      .delete(`students/${studentId}/${teacherId}`)
      .then(() => {
        navigate("/admin/ourstudents");
      })
      .catch((err) => console.error(err));
  };

  const updateTeacher = (newTeacherId, newTeacherName) => {
    setSelectedTeacher(newTeacherName);
    axios
      .put(`update-teacher/${student._id}/${student.teacherId}/${newTeacherId}`)
      .then(() => {
        setIsSuccessUpdate(true);
      })
      .catch((err) => {
        setIsSuccessUpdate(false);
        console.log(err);
      })
      .finally(() => {
        setTeacherClick(false);
      });
  };

  const handleTimeChange = async (newTime) => {
    if (editingSchedule) {
      console.log(`Updating time for ${editingSchedule.day} to ${newTime}`);

      try {
        const response = await axios.post("student/edit-lesson-time", {
          studentId: student._id,
          day: editingSchedule.day,
          newTime,
        });

        if (response.status === 200) {
          console.log("Time updated successfully on the server", response.data);
          const updatedSchedule = student.classSchedule.map((item) =>
            item.day === editingSchedule.day ? { ...item, time: newTime } : item
          );
          setStudent({ ...student, classSchedule: updatedSchedule });
        } else {
          console.error("Failed to update time", response.data);
        }
      } catch (error) {
        console.error("Error updating time on the server", error);
      } finally {
        setEditingSchedule(null);
      }
    }
  };

  const cancelEditing = () => {
    setEditingSchedule(null);
  };

  const removeDay = (day, time) => {
    axios
      .post("/student/remove-day", {
        studentId: student._id,
        day: day,
        time: time,
      })
      .then(({data}) => {
        setStudent({ ...student, classSchedule: data.updatedSchedule });
      })
      .catch((err) => console.error(err));
  };

  const addNewDayAndTime = async () => {
    if (!newDay || !newTime) {
      alert("Оберіть день та час");
      return;
    }

    try {
      const response = await axios.post("student/add-schedule", {
        studentId: student._id,
        day: newDay,
        time: newTime,
      });

      if (response.status === 200) {
        console.log("Добавлено успешно", response.data);
        setStudent({
          ...student,
          classSchedule: response.data.updatedSchedule,
        });
        setNewDay("");
        setNewTime("");
      } else {
        console.error("Ошибка при добавлении:", response.data);
      }
    } catch (error) {
      console.error("Ошибка добавления дня и времени:", error);
    }
  };

  return (
    <PagesWrapper>
      <TopPageTitle value={student.name} />
      <styled.List>
        <styled.Item>
          <styled.ItemTitle>
            Ім’я: {student.name} - id:{student.studentId}
          </styled.ItemTitle>
        </styled.Item>
        <styled.Item>
          <styled.ItemTitle>Група: {student.ageGroup}</styled.ItemTitle>
        </styled.Item>
        <styled.Item>
          <styled.ItemTitle>Рівень: {student.englishLevel}</styled.ItemTitle>
        </styled.Item>
        <styled.Item>
          <styled.EditWrapper>
            <styled.ItemTitle>
              Викладач:{" "}
              {selectedTeacher.length <= 0
                ? student.teacherName
                : selectedTeacher}
            </styled.ItemTitle>
            <styled.Pen onClick={() => setTeacherClick((prev) => !prev)} />
          </styled.EditWrapper>
          {teacherClick && (
            <styled.SelectWrapper>
              <SelectTeacher
                setLoading={setLoading}
                loading={loading}
                setTeachers={setTeachers}
                teachers={teachers}
                setTeacherClick={setTeacherClick}
                teacherClick={teacherClick}
                handleClick={updateTeacher}
                selectedTeacher={selectedTeacher}
                title={"Виберіть нового вчителя"}
              />
            </styled.SelectWrapper>
          )}
          {isSuccessUpdate && (
            <p style={{ color: "green" }}>Вчителя успішно оновленно</p>
          )}
          {isSuccessUpdate === false && (
            <p style={{ color: "red" }}>Щось пішло не так ....</p>
          )}
        </styled.Item>

        <styled.Item>
          <styled.ItemTitle>
            Номер телефону: {student.phoneNumber}
          </styled.ItemTitle>
        </styled.Item>
        <styled.Item>
          <styled.ItemTitle>
            Пакет уроків: {student.lessonPackage}
          </styled.ItemTitle>
        </styled.Item>
        <styled.Item>
          <styled.ItemTitle>Розклад: </styled.ItemTitle>
          {student?.classSchedule?.map(({ day, time }) => (
            <styled.ScheduleWrapper key={day}>
              {editingSchedule?.day === day ? (
                <div style={{ marginBottom: "15px" }}>
                  <input
                    type="time"
                    value={editingSchedule.time}
                    onChange={(e) =>
                      setEditingSchedule({
                        ...editingSchedule,
                        time: e.target.value,
                      })
                    }
                    style={{ marginRight: "15px" }}
                  />
                  <button
                    onClick={() => handleTimeChange(editingSchedule.time)}
                    style={{ marginRight: "15px" }}
                  >
                    Зберегти
                  </button>
                  <button onClick={cancelEditing}>Скасувати</button>
                </div>
              ) : (
                <>
                  <styled.ItemTitle>
                    {day}: {time}
                  </styled.ItemTitle>
                  <styled.EditIconsButton
                    onClick={() => setEditingSchedule({ day, time })}
                  >
                    <FaClock />
                  </styled.EditIconsButton>
                  <styled.EditIconsButton onClick={() => removeDay(day, time)}>
                    <FaTrash />
                  </styled.EditIconsButton>
                </>
              )}
            </styled.ScheduleWrapper>
          ))}
        </styled.Item>
        <styled.Item>
          <styled.ItemTitle>Додати новий день:</styled.ItemTitle>
          <styled.AddScheduleWrapper style={{ display: "flex", alignItems: "center" }}>
            <styled.Select
              value={newDay}
              onChange={(e) => setNewDay(e.target.value)}
              style={{ marginRight: "15px" }}
            >
              <option value="" >Оберіть день</option>
              <option value="monday">Понеділок</option>
              <option value="tuesday">Вівторок</option>
              <option value="wednesday">Середа</option>
              <option value="thursday">Четвер</option>
              <option value="friday">П'ятниця</option>
              <option value="saturday">Субота</option>
              <option value="sunday">Неділя</option>
            </styled.Select>
            <styled.TimeInput
              type="time"
              value={newTime}
              onChange={(e) => setNewTime(e.target.value)}
              style={{ marginRight: "15px" }}
            />
            <styled.AddButton onClick={addNewDayAndTime}>Додати</styled.AddButton>
          </styled.AddScheduleWrapper>
        </styled.Item>
        <styled.RemoveBtn
          onClick={() => handleRemove(student._id, student.teacherId)}
        >
          Видалити учня
        </styled.RemoveBtn>
      </styled.List>
    </PagesWrapper>
  );
}

export default StudentInfo;
