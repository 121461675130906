import { getByGroupAndLevel } from "./getByGroupAndLevel";

export const fetchMaterials = async (setLoading, group, level, accessType, setMaterials, setAddedLesson) => {
   setLoading(true);
   try {
     const materials = await getByGroupAndLevel(group, level, accessType);
     setMaterials(materials);
   } catch (error) {
     console.error('Error fetching materials:', error);
   } finally {
     setLoading(false);
     if(setAddedLesson){
      setAddedLesson(false)
     }
     
   }
 };