import styled from "styled-components";

export const Wrapper = styled.div`
   padding: 15px 0px 0px 10px;
   width: 100%;
   margin-top: 10px;
`
export const InputName = styled.p`
   font-family: Raleway;
   font-size: 13px;
   font-weight: 800;
   text-align: left;
   margin-bottom: 5px;

`
export const InputWrapper = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   &:not(:last-child){
      margin-bottom: 15px;
   }
`
export const Input = styled.input`
   border: 1px solid #8E8E8E;
   border-radius: 10px ;
   padding: 10px 30px 10px 10px;
   box-shadow: 0px 4px 4px 0px #00000040;
   width: 30%;
   font-size: 14px;
   font-weight: 400;
   &:hover {
      border: 1px solid #A09EFF;
   };

   &::placeholder {
      font-family: Raleway;
      font-size: 12px;
      font-weight: 400;
      line-height: 11px;
     

   };
   &:-webkit-autofill {
   
    -webkit-text-fill-color: black !important; 
   };
`


export const RadioButtonWrapper = styled.div`
   display: flex;
   align-items: center;
   margin-bottom: 15px;
   & > input {
      background-color: ${props => props.checked ? '#A09EFF' : ''};
      border-color: ${props => props.checked ? '#A09EFF' : ''} ;
   }
`
export const RadioButton = styled.input.attrs({ type: 'radio' })`
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 1px solid #000;
 margin-left: 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    border-color: #A09EFF;
  }
`;

export const ErrorMessage = styled.p`
   color: red;
   font-size: 10px;
   margin: 5px 0px 0px 5px;
`

export const Btn = styled.button`
   font-family: Raleway;
   font-size: 17px;
   font-weight: 700;
   line-height: 18.7px;
   color: rgb(0, 0, 0);
   max-width: 200px;
   border-radius: 15px;
   background: linear-gradient(90deg, rgb(210, 209, 255) 0%, rgb(255, 225, 162) 100%);
   padding: 10px 35px;
   cursor: pointer;
   transition: 0.3s;
`
