import { useState, useEffect } from "react";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import * as styled from "./Materials.styled";
import { getLevels } from "../../../helpers/levels/getLevels";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";
import { useDispatch } from "react-redux";
import useAuthData from "../../../hooks/useAuthData";
import { trialLessons } from "../../../helpers/materials/trials";
import { materialGroups } from "../../../helpers/materials/getByGroupAndLevel";

function MaterialsTeacher({ isTeacher, accessType }) {
  const [group, setGroup] = useState(null);
  const [levels, setLevels] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const data = useAuthData(dispatch);
  const navigate = useNavigate();

  useEffect(() => {
    if (!group || !data) return;
    setLoading(true);
    getLevels(group, isTeacher ? data.accessType : accessType)
      .then(({ levels }) => setLevels(levels))
      .finally(() => setLoading(false));
  }, [accessType, data, group, isTeacher]);

  return (
   <PagesWrapper>
      <TopPageTitle value={"Матеріали"} />
      <styled.List>
        {materialGroups.map(({ value, name }, index) => (
          <styled.Item
            isAlternate={index % 2 !== 0}
            key={value}
            onClick={() => setGroup(value)}
            isGroup={group === value}
          >
            <styled.LessonName>{name}</styled.LessonName>
          </styled.Item>
        ))}
        {loading ? (
          <Loader />
        ) : (
          <>
            {levels?.map((level) => (
              <styled.Item
                isLevel
                key={level}
                onClick={() => navigate(`${group}/${level}`)}
              >
                <styled.LessonName isLevel>{level}</styled.LessonName>
              </styled.Item>
            ))}
            {trialLessons
              .filter(({ type }) =>
                isTeacher ? type === data?.accessType : type === accessType
              )
              .flatMap(({ lesson }) =>
                lesson.map(({ name, url }) => {
                  if (name !== group) return null;

                  return (
                    <styled.Item isLevel key={url}>
                      <styled.TrialLink
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <styled.LessonName isLevel>
                          Пробний урок
                        </styled.LessonName>
                      </styled.TrialLink>
                    </styled.Item>
                  );
                })
              )
              .filter(Boolean)}
          </>
        )}
      </styled.List>
   </PagesWrapper>
  );
}

export default MaterialsTeacher;
