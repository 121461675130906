import { useState } from "react";
import AddMaterial from "../AddMaterial/AddMaterial";
import Loader from "../Loader/Loader";
import TopPageTitle from "../TopPageTitle/TopPageTitle";
import * as styled from "./MaterialsLevel.styled";

function MaterialsLevel({
  topTitle,
  materials,
  loading,
  englishLevel,
  isTeacher,
  isAdmin,
  setAddedLesson,
  isStudent
}) {
  const [openTopics, setOpenTopics] = useState({});
  const [newTopic, setNewTopic] = useState(null);
  const [createdTopics, setCreatedTopics] = useState([]);

  if (loading) {
    return <Loader />;
  }

  const toggleTopicOpen = (topicName) => {
    setOpenTopics((prev) => ({
      ...prev,
      [topicName]: !prev[topicName],
    }));
    setNewTopic(null); 
  };

  const openNewTopic = (topicName) => {
    const incrementedTopic = topicName.replace(
      /Topic (\d+)/,
      (match, number) => `Topic ${+number + 1}`
    );
    setNewTopic(incrementedTopic);
    setCreatedTopics((prev) => [...prev, incrementedTopic]);
  };

  const noTopics = materials.length === 0 || materials.every((m) => m.topics.length === 0);

  return (
    <>
      <TopPageTitle value={topTitle} englishLevel={englishLevel} />
      <styled.MaterialsWrapper>
        {noTopics ? (
          isAdmin && (
            <AddMaterial
              noMaterials
              setAddedLesson={setAddedLesson}
              setIsOpen={() => {}}
              topicName="Topic 1"
            />
          )
        ) : isAdmin && (
          materials.map((material) =>
            material.topics.map((topic, index) => {
              const isLastTopic =
                index === material.topics.length - 1 &&
                !createdTopics.includes(
                  `Topic ${+topic.name.match(/\d+/)[0] + 1}`
                );

              return (
                <styled.TopicWrapper key={topic.name}>
                  <styled.TopicName>{topic.name}</styled.TopicName>
                  <styled.List>
                    {topic.lessons.map((lesson, index) => (
                      <styled.Item
                        key={lesson._id}
                        isAlternate={index % 2 !== 0}
                        isRevision={lesson.type === "Revision"}
                      >
                        <styled.LessonName href={lesson.url} target="_blank">
                          {lesson.name}
                        </styled.LessonName>
                        {lesson.answers  && (
                          <styled.Answers
                            href={lesson.answers}
                            target="_blank"
                          >
                            answers
                          </styled.Answers>
                        )}
                      </styled.Item>
                    ))}
                  </styled.List>
                  <styled.BtnWrapper>
                    <styled.Btn
                      isOpen={openTopics[topic.name]}
                      onClick={() => toggleTopicOpen(topic.name)}
                    >
                      {openTopics[topic.name] ? "-" : "+"}
                    </styled.Btn>

                    {isAdmin && openTopics[topic.name] && (
                      <AddMaterial
                        topicName={topic.name}
                        setAddedLesson={setAddedLesson}
                        setIsOpen={() => toggleTopicOpen(topic.name)}
                      />
                    )}

                    {isAdmin && isLastTopic && (
                      <styled.TopicBtn onClick={() => openNewTopic(topic.name)}>
                        Додати урок в наступний Topic
                      </styled.TopicBtn>
                    )}

                    {isAdmin &&
                      newTopic ===
                        `Topic ${+topic.name.match(/\d+/)[0] + 1}` && (
                        <AddMaterial
                          topicName={newTopic}
                          setAddedLesson={setAddedLesson}
                          setIsOpen={() => setNewTopic(null)}
                        />
                      )}
                  </styled.BtnWrapper>
                </styled.TopicWrapper>
              );
            })
          )
        )}

        {(isTeacher || isStudent) && materials.length > 0 ? (
            materials.map((material) =>
            material.topics.map((topic) => (
               <styled.TopicWrapper key={topic.name}>
                  <styled.TopicName>{topic.name}</styled.TopicName>
                  <styled.List>
                  {topic.lessons.map((lesson, index) => (
                     <styled.Item key={lesson._id} isAlternate={index % 2 !== 0} isRevision={lesson.type === "Revision"}>
                        <styled.LessonName href={lesson.url} target="_blank">{lesson.name}</styled.LessonName>
                        {(lesson.answers && isTeacher) && <styled.Answers href={lesson.answers} target="_blank">answers</styled.Answers>}
                     </styled.Item>
                  ))}
                  </styled.List>
               </styled.TopicWrapper>
            ))
            )
         ) : isTeacher && (
            <p>No materials available.</p>
         )}
      </styled.MaterialsWrapper>
    </>
  );
}

export default MaterialsLevel;

