import { useEffect, useState } from "react";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import * as styled from "./LessonReport.styled";
import { useSelector } from "react-redux";
import { lessonReport } from "../../../helpers/lessonReport";
import { getByGroupAndLevel } from "../../../helpers/materials/getByGroupAndLevel";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";
import DropDown from "./DropDown/DropDown";
import Loader from '../../../components/Loader/Loader'
import axios from "../../../axios";
const lessonStatus = [
  { name: "Відбувся", value: "success" },
  { name: "Не відбувся з провини учня", value: "failed-by-student" },
  { name: "Скасовано завчасно", value: "failed" },
];
function LessonReport() {
  const { data } = useSelector((state) => state.auth);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState("");
  const [lessonName, setLessonName] = useState("");
  const [comment, setComment] = useState("");
  const [commentForTeacher, setCommentForTeacher] = useState("");
  const [message, setMessage] = useState("");
  const [studentFilter, setStudentFilter] = useState("");
  const [lessonFilter, setLessonFilter] = useState("");
  const [studentAgeGroup, setStudentAgeGroup] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [studentShow, setStudentShow] = useState(false);
  const [statusShow, setStatusShow] = useState(false);
  const [lessonShow, setLessonShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState(data.students);
  const [reschedules, setReschedules] = useState([]);

  useEffect(() => {
    if(!data) return
    axios.get(`lesson-reschedule/${data._id}`)
    .then(res => setReschedules(res.data.lesson))
    .catch(err => console.error(err))
  },[data])
  const fetchLessons = async (group, level) => {
    try {
      const response = await getByGroupAndLevel(group, level, data.accessType);

      const topics = response[0]?.topics || [];

      const allLessons = topics.flatMap((topic) => topic.lessons);
      setLessons(allLessons);
    } catch (error) {
      console.error("Error fetching lessons:", error);
    }
  };

  const handleStudentChange = (studentId, ageGroup) => {
    setSelectedStudent(studentId);
    setStudentAgeGroup(ageGroup);
    const student = students.find((s) => s._id === studentId);
    if (student) {
      fetchLessons(student.ageGroup, student.englishLevel);
    }
  };

  const handleMarkLesson = async () => {
    setLoading(true)
    try {
      if (selectedStatus === "success") {
        if (selectedLesson.length <= 0 || comment.length <= 0) {
           setMessage(
            "Не вдалося відмітити урок, перевірте чи все ви заповнили"
          );
          setIsSuccess(false)

          return
        }
      }
      const salary = data.salary.find(
        (value) => value.name === studentAgeGroup
      );

      await lessonReport(data._id, {
        studentId: selectedStudent,
        teacherId: data._id,
        teacherName: data.name,
        lesson: selectedLesson,
        lessonName: lessonName,
        comment,
        salary: salary.value,
        lessonType: selectedStatus,
        commentForTeacher,
        lessonsLength: lessons.length,
        accessType: data.accessType
      });
      setIsSuccess(true)
      setMessage("Урок успішно додано");
      setSelectedStudent("");
      setComment("");
      setSelectedLesson("");
      setLessons([]);
      setSelectedStatus("");
      setCommentForTeacher("");
      setStudentShow(false);
      setLessonShow(false);
      setStatusShow(false);
      setLoading(false)
    } catch (error) {
      setIsSuccess(false)
      setMessage("Не вдалося відмітити урок, перевірте чи все ви заповнили");
      setLoading(false)
    }
  };
  useEffect(() => {
    if (reschedules.length > 0) {
        const updatedStudents = [...data.students];
        const existingIds = new Set(updatedStudents.map(student => student._id));

        reschedules.forEach(reschedule => {
            if (reschedule.type !== 'teacher-changed') return setStudents(data.students);;
            if (!existingIds.has(reschedule._id)) {
                updatedStudents.push({
                    _id: reschedule.studentId,
                    name: reschedule.studentName,
                    studentId: reschedule.student_id,
                    ageGroup: reschedule.ageGroup,
                    englishLevel: reschedule.englishLevel,
                });
                existingIds.add(reschedule._id);
            }
        });

        setStudents(updatedStudents);
    }else {
      setStudents(data.students);
    }
}, [reschedules, data.students]);
 
  if (!data) return null;
  return (
    <PagesWrapper>
      <TopPageTitle value={"Відмітка про урок"} />
      {data?.students?.length > 0 || reschedules?.length > 0 ? (
        <styled.Form>
          <styled.Wrapper>
            <DropDown
              title={"Учень"}
              setOpen={setStudentShow}
              open={studentShow}
            />

            {studentShow && (
              <styled.DropdownWrapper>
                <styled.Input
                  type="text"
                  placeholder="Виберіть або впишіть учня"
                  value={studentFilter}
                  onChange={(e) => setStudentFilter(e.target.value)}
                />
                {students
                  .filter((student) =>
                    student.name
                      .toLowerCase()
                      .includes(studentFilter.toLowerCase())
                  )
                  .map((student) => (
                    <styled.Select
                      key={student._id}
                      onClick={() =>
                        handleStudentChange(student._id, student.ageGroup)
                      }
                      selected={student._id === selectedStudent}
                    >
                      <styled.Option>
                        {student.name} - id:{student.studentId}
                      </styled.Option>
                    </styled.Select>
                  ))}
              </styled.DropdownWrapper>
            )}
          </styled.Wrapper>
          <styled.Wrapper>
            <DropDown
              title={"Статус уроку"}
              setOpen={setStatusShow}
              open={statusShow}
            />
            {statusShow && (
              <styled.DropdownWrapper>
                {lessonStatus.map(({ name, value }) => (
                  <styled.Select
                    key={value}
                    onClick={() => setSelectedStatus(value)}
                    selected={value === selectedStatus}
                  >
                    <styled.Option>{name}</styled.Option>
                  </styled.Select>
                ))}
              </styled.DropdownWrapper>
            )}
          </styled.Wrapper>
         {selectedStatus !== "failed" && <styled.Wrapper>
            {selectedStatus !== "failed-by-student" && (
              <>
                <DropDown
                  title={"Урок"}
                  setOpen={setLessonShow}
                  open={lessonShow}
                />

                {lessonShow && (
                  <styled.DropdownWrapper>
                    <styled.Input
                      type="text"
                      placeholder="Виберіть або впишіть урок"
                      value={lessonFilter}
                      onChange={(e) => setLessonFilter(e.target.value)}
                    />

                    {lessons.length > 0 &&
                      lessons
                        .filter((lesson) =>
                          lesson.name
                            .toLowerCase()
                            .includes(lessonFilter.toLowerCase())
                        )
                        .map((lesson) => (
                          <styled.Select
                            onClick={() => {
                              setSelectedLesson(lesson.url);
                              setLessonName(lesson.name);
                            }}
                            selected={lesson.url === selectedLesson}
                            key={lesson._id}
                          >
                            <styled.Option>{lesson.name}</styled.Option>
                          </styled.Select>
                        ))}
                  </styled.DropdownWrapper>
                )}
              </>
            )}
          </styled.Wrapper>}
          {selectedStatus !== "failed" ? <>
            { selectedStatus !== "failed-by-student" && (
              <>
                <styled.TextArea
                  placeholder="Відгук про урок"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required
                />
                <styled.TextArea
                  placeholder="Помітка про урок для себе"
                  value={commentForTeacher}
                  onChange={(e) => setCommentForTeacher(e.target.value)}
                  required
                />
              </>
            )}
            {!loading 
            ? <styled.Button onClick={handleMarkLesson}>
              Відмітити урок
            </styled.Button> 
            : (
              <styled.LoaderWrapper>
                <Loader customWidth={150}/>
              </styled.LoaderWrapper>
            
            )}
          </> : (
            <styled.Message isSuccess={isSuccess}>
              У такому випадку відмітка уроку не є необхідною
            </styled.Message>
          )}
          {message && <styled.Message isSuccess={isSuccess}>{message}</styled.Message>}
        </styled.Form>
      ) : (
        <p>Поки що у вас немає учнів 🙁</p>
      )}
    </PagesWrapper>
  );
}

export default LessonReport;
