import * as styled from "./ModalBlock.styled"

function ModalBlock({lesson, joinedLesson, cancelLesson}){
  console.log(lesson)
   if (!lesson) return null;
   
  return (
    <>
      <styled.studentInfo>Тип урока:  {lesson.type} </styled.studentInfo>
      <styled.studentInfo>Ім'я:  {lesson.name} </styled.studentInfo>
      <styled.studentInfo>ID:  {lesson.studentId} </styled.studentInfo>
      <styled.studentInfo>Час: {lesson.time}</styled.studentInfo>
      <styled.studentInfo>Вікова група: {lesson.ageGroup}</styled.studentInfo>
      <styled.studentInfo>Рівень знань: {lesson.englishLevel}</styled.studentInfo>
      <a href={lesson.googleMeetLink} target="_blank" rel="noopener noreferrer" onClick={() => joinedLesson()}>
         <styled.JoinButton>Розпочати урок</styled.JoinButton>
      </a>
     {lesson.type !== "Trial" && <styled.CancelLesson onClick={() => cancelLesson(lesson.studentId)}>Скасувати урок</styled.CancelLesson>}
    </>
  )
};

export default ModalBlock;
