import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from '../axios'
const AdminContext = createContext();

export const useAdmin = () => {
  return useContext(AdminContext);
};

export const AdminProvider = ({ children }) => {
   const [adminInfo, setAdminInfo] = useState(null)
   useEffect(() => {
      axios.get('auth/me')
      .then(res =>{
         
        setAdminInfo(res.data)
      }).catch((err) => {
        console.log(err)
      })
    }, []);
    
  return (
    <AdminContext.Provider value={{ adminInfo, setAdminInfo }}>
      {children}
    </AdminContext.Provider>
  );
};