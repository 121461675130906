import styled from "styled-components";
import { FaPen } from "react-icons/fa";

export const Wrapper = styled.div`
   padding-top: 15px;
`

export const List = styled.ul`
   display: flex;
   flex-direction: column; 
   align-items: flex-start;
`

export const Item = styled.li`
   
  padding: 10px 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   &:not(:last-child){
      margin-bottom: 25px;
   }
`
export const ScheduleWrapper = styled.div`
   display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: #eaf7ff;
  border: 1px solid #d0e8ff;
  border-radius: 8px;
  margin-bottom: 8px;
`
export const EditWrapper= styled.div`
 display: flex;
 gap: 15px;
`
export const ItemTitle = styled.p `

`
export const Pen = styled(FaPen)`
transition: 0.3s;
cursor: pointer;
 &:hover{
  opacity: 0.7;
 }
`
export const SelectWrapper = styled.div`
 margin-top: 30px;
 max-width: 40%;
`
export const RemoveBtn = styled.button`
   width: 20% !important;
   background-color: #A09EFF;
   padding: 10px;
   transition: 0.3s;
   border-radius: 10px;
   margin-left: auto;
   &:hover{
    background-color: red;
   }

`

export const EditIconsButton = styled.button`
  background: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1.2em;
  margin-left: 10px;

  &:hover {
    color: #0056b3;
  }
  
`;

export const AddScheduleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  background-color: #f0f9f0;
  border: 1px solid #cfe8cf;
  border-radius: 8px;
`;

export const Select = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: 0.3s;
  &:focus, &:hover {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

export const TimeInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
   transition: 0.3s;
  &:focus, &:hover {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

export const AddButton = styled.button`
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
   transition: 0.3s;
  &:hover {
    background-color: #218838;
  }
`;