import { useNavigate } from "react-router-dom";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";
import * as styled from "./TrialLessons.styled";
import { useEffect, useState } from "react";
import axios from '../../../axios';
import { RemoveIcon } from "../../../styles/components/RemoveIcon";

const langs = [
  { key: 'en', value: 'English' },
  { key: 'de', value: 'German' },
  { key: 'pl', value: 'Polish' }
];

function TrialLessons({ adminInfo }) {
  const navigate = useNavigate();
  const [lessons, setLessons] = useState([]);
  const [removedInTeacher, setRemovedInTeacher] = useState(false);

  useEffect(() => {
    axios.get('trial-lesson')
      .then(res => {
        setLessons(res.data);
      })
      .catch(err => console.error(err));
  }, [removedInTeacher]);

  const handleRemove = (lessonId, teacherId) => {
    axios.delete(`trial-lesson/${lessonId}/${teacherId}`)
      .then(() => {
        const newLessons = lessons.filter(lesson => lesson._id !== lessonId);
        setLessons(newLessons);
      })
      .catch(err => console.error(err));
  };

  const removeFromTeacher = (lessonId, teacherId) => {
    axios.delete(`trial-lesson/remove-from-teacher/${lessonId}/${teacherId}`)
      .then(() => {
        setRemovedInTeacher(true);
      })
      .catch(err => console.error(err));
  };

  return (
    <PagesWrapper>
      <TopPageTitle value="Пробні уроки" />

      <styled.List isAllAdmin={adminInfo.accessType === "all"}>
        {adminInfo.accessType !== 'all' && (
          <styled.AddTrialItem onClick={() => navigate(`add-trial-lesson`)}>
            <styled.ItemTitle>+ Пробний урок</styled.ItemTitle>
          </styled.AddTrialItem>
        )}

        {adminInfo.accessType === 'all' ? (
          langs.map(({ key, value }) => (
            <styled.Column key={key}>
              <styled.ColumnTitle>{value}</styled.ColumnTitle>
              {lessons
                .filter(lesson => lesson.accessType === key)
                .map(({ name, studentId, _id, teacherId, teacherSchoolId, teacherName }) => (
                  <styled.TrialItem key={_id} isAllAdmin={adminInfo.accessType === "all"}>
                    <styled.ItemTitle>{name} - id({studentId})</styled.ItemTitle>
                    <styled.ItemTitleTeacher>
                      Teacher: {teacherName} - id({teacherSchoolId})
                    </styled.ItemTitleTeacher>
                    
                  </styled.TrialItem>
                ))}
            </styled.Column>
          ))
        ) : (
          lessons
            .filter(({ accessType }) => accessType === adminInfo.accessType)
            .map(({ name, studentId, _id, teacherId, teacherSchoolId, teacherName }) => (
              <styled.TrialItem key={_id}>
                <styled.ItemTitle>{name} - id({studentId})</styled.ItemTitle>
                <styled.ItemTitleTeacher>
                  Teacher: {teacherName} - id({teacherSchoolId})
                </styled.ItemTitleTeacher>
                {teacherId === 'removed' && (
                  <RemoveIcon onClick={() => handleRemove(_id, teacherId)} />
                )}
                {teacherId !== 'removed' && (
                  <styled.RemoveInTeacher onClick={() => removeFromTeacher(_id, teacherId)}>
                    Remove from teacher only
                  </styled.RemoveInTeacher>
                )}
              </styled.TrialItem>
            ))
        )}
      </styled.List>
    </PagesWrapper>
  );
}

export default TrialLessons;
