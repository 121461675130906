import "./index.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import Admin from "./pages/AdminRoom/Admin/Admin";
import Student from "./pages/StudentRoom/Student/Student";
import СlassSchedule from "./pages/StudentRoom/СlassSchedule/СlassSchedule";
import Teacher from "./pages/TeacherRoom/Teacher/Teacher";
import OurStudents from "./pages/AdminRoom/OurStudents/OurStudents";
import StudentsGroup from "./pages/AdminRoom/StudentsGroup/StudentsGroup";
import StudentInfo from "./pages/AdminRoom/StudentInfo/StudentInfo";
import AddStudent from "./pages/AdminRoom/AddStudent/AddStudent";
import OurTeachers from "./pages/AdminRoom/OurTeachers/OurTeachers";
import TeacherInfo from "./pages/AdminRoom/TeacherInfo/TeacherInfo";
import AddTeacher from "./pages/AdminRoom/AddTeacher/AddTeacher";
import Materials from "./pages/StudentRoom/Materials/Materials";
import MaterialsTeacher from "./pages/TeacherRoom/Materials/Materials";
import MaterialsGroupLevel from "./pages/TeacherRoom/MaterialsGroupLevel/MaterialsGroupLevel";
import Schedule from "./pages/TeacherRoom/Schedule/Schedule";
import MyStudents from "./pages/TeacherRoom/MyStudents/MyStudents";
import LessonReport from "./pages/TeacherRoom/LessonReport/LessonReport";
import Finance from "./pages/TeacherRoom/Finance/Finance";
import Homework from "./pages/StudentRoom/Homework/Homework";
import LessonPayment from "./pages/AdminRoom/LessonPayment/LessonPayment";
import Payment from "./pages/StudentRoom/Payment/Payment";
import AddTrialLesson from "./pages/AdminRoom/AddTrialLesson/AddTrialLesson";
import TeacherSalary from "./pages/AdminRoom/TeacherSalary/TeacherSalary";
import { MenuProvider } from "./context/MenuContext";
import GeneralPage from "./components/GeneralPage/GeneralPage";
import TrialLessons from "./pages/AdminRoom/TrialLessons/TrialLessons";
import MarkedLessons from "./pages/TeacherRoom/MarkedLessons/MarkedLessons";
import LessonReschedule from "./pages/AdminRoom/LessonReschedule/LessonReschedule";
import { useEffect, useState } from "react";
import axios from "./axios";
import { AdminProvider } from "./context/AdminInfo";


function App() {
  const navigate = useNavigate();
  const [adminInfo, setAdminInfo] = useState('');
  
  useEffect(() => {
    axios
      .get("auth/me")
      .then((res) => {
        if (res.data.tokens.length === 0) {
          localStorage.removeItem("token");
          navigate("/");
        } else {
          setAdminInfo(res.data); 
        }
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
      });
  }, [navigate]);

  

 
  return (
    <div className="App">
     
      
      <Routes>
      <Route path="/" element={<Login />} />
          <Route
            path="/admin"
            element={
              <MenuProvider>
                <Admin adminInfo={adminInfo} />
              </MenuProvider>
            }
          >
            <Route path="general" element={<GeneralPage />} />
            <Route path="ourstudents" element={<OurStudents />} />
            <Route
              path="ourstudents/:value"
              element={<StudentsGroup adminInfo={adminInfo} />}
            />
            <Route path="ourstudents/:value/:id" element={ <AdminProvider><StudentInfo /></AdminProvider>} />
            <Route
              path="ourstudents/:value/addstudent"
              element={
                <AdminProvider>
                  <AddStudent />
                </AdminProvider>
              }
            />
            <Route
              path="ourteachers"
              element={<OurTeachers adminInfo={adminInfo} />}
            />
            <Route path="ourteachers/:id" element={<TeacherInfo />} />
            <Route
              path="ourteachers/addteacher"
              element={<AddTeacher adminInfo={adminInfo} />}
            />
            <Route
              path="lesson-payment"
              element={<LessonPayment adminInfo={adminInfo} />}
            />
            <Route
              path="teacher-salary"
              element={<TeacherSalary adminInfo={adminInfo} />}
            />
            <Route
              path="trial-lessons"
              element={<TrialLessons adminInfo={adminInfo} />}
            />
            <Route
              path="trial-lessons/add-trial-lesson"
              element={
                <AdminProvider>
                  {" "}
                  <AddTrialLesson />{" "}
                </AdminProvider>
              }
            />
            <Route
              path="lesson-reschedule"
              element={<LessonReschedule adminInfo={adminInfo} />}
            />
            <Route path="materials" element={<MaterialsTeacher accessType={adminInfo.accessType}/>} />
            <Route
              path="materials/:group/:level"
              element={<AdminProvider><MaterialsGroupLevel isAdmin/> </AdminProvider>}
            />
          </Route>
          <Route
            path="/student"
            element={
              <MenuProvider>
                <Student />
              </MenuProvider>
            }
          >
            <Route path="general" element={<GeneralPage isStudent />} />
            <Route path="schedule" element={<СlassSchedule />} />
            <Route path="materials" element={<Materials />} />
            <Route path="homework" element={<Homework />} />
            <Route path="payment" element={<Payment />} />
          </Route>
          <Route
            path="/teacher"
            element={
              <MenuProvider>
                <Teacher />
              </MenuProvider>
            }
          >
            <Route path="general" element={<GeneralPage isTeacher />} />
            <Route path="materials" element={<MaterialsTeacher isTeacher/>} />
            <Route
              path="materials/:group/:level"
              element={<MaterialsGroupLevel />}
            />
            <Route path="schedule" element={<Schedule />} />
            <Route path="my-students" element={<MyStudents />} />
            <Route path="lesson-report" element={<LessonReport />} />
            <Route path="finance" element={<Finance />} />
            <Route path="marked-lessons" element={<MarkedLessons />} />
          </Route>
          <Route
            path="/trial-student"
            element={
              <MenuProvider>
                <Student isTrial />
              </MenuProvider>
            }
          >
            <Route path="general" element={<GeneralPage isTrial />} />
            <Route path="schedule" element={<СlassSchedule />} />
            <Route path="payment" element={<Payment isTrial />} />
          </Route>
          <Route path="*" element={<div>Page Not Found</div>} />
        </Routes>
      
    </div>
  );
}

export default App;
