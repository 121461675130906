import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import Modal from "../../../components/Modal/Modal";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";
import useAuthData from "../../../hooks/useAuthData";
import Loader from "../../../components/Loader/Loader";
import axios from "../../../axios";
import RescheduleTeacherBlock from "../../../components/RescheduleTeacherBlock/RescheduleTeacherBlock";
const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

function Schedule() {
  const dispatch = useDispatch();
  const data = useAuthData(dispatch);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [reschedules, setReschedules] = useState([]);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [rescheduleSubmited, setRescheduleSubmited] = useState(false);
  const [loading, setLoading] = useState(false)

  const joinedLesson = () => {
    axios.post("teacher/joined-lesson", {
      lessonData: {
        teacherName: data.name,
        studentName: selectedLesson.name,
        studentId: selectedLesson.studentId,
        lessonTime: selectedLesson.time,
        accessType: data.accessType
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    axios
      .get(`lesson-reschedule/${data._id}`)
      .then((res) => {
        setReschedules(res.data.lesson);
        if (rescheduleSubmited) setRescheduleSubmited(false);
      })
      .catch((err) => console.error(err));
  }, [data, rescheduleSubmited]);

  if (!data || !data.students || !data.trialLessons || !reschedules) {
    return <Loader />;
  }

  const handleCloseModal = () => {
    setSelectedLesson(null);
    setRescheduleModal(false);
  };

  const cancelLesson = (id) => {
    setLoading(true)
    const studentId = data.students.find((res) => res.studentId === id)._id;
    const dayName = days[selectedLesson.day];
    const lesson = { day: dayName, time: selectedLesson.time };
    const teacherId = data._id;
    axios
      .post(`lesson-reschedule/cancel/${studentId}/${teacherId}`, {
        lessonReschedule: lesson,
      })
      .then(() => {
        setRescheduleSubmited(true)
        setSelectedLesson(null)
        axios.post(`teacher/rescheduled/${teacherId}/${studentId}/cancel`, { lessonReschedule: lesson })
      })
      .catch(() => console.log('Щось пішло не так'))
      .finally(() => setLoading(false))
    console.log("cancel");
    console.log(studentId);
  };

  return (
    <PagesWrapper>
      <TopPageTitle value={"Графік"} />
      <RescheduleTeacherBlock
        teacher={data}
        reschedules={reschedules}
        handleCloseModal={handleCloseModal}
        joinedLesson={joinedLesson}
        setRescheduleSubmited={setRescheduleSubmited}
        selectedLesson={selectedLesson}
        setSelectedLesson={setSelectedLesson}
        rescheduleModal={rescheduleModal}
        setRescheduleModal={setRescheduleModal}
      />
      {selectedLesson && (
        <Modal
          lesson={selectedLesson}
          onClose={handleCloseModal}
          joinedLesson={joinedLesson}
          cancelLesson={cancelLesson}
          loading={loading}
        />
      )}
    </PagesWrapper>
  );
}

export default Schedule;
