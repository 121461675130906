import * as styled from "./AvatarBlock.styled"
import avatar from '../../assets/images/avatar1.png'

function AvatarBlock({userName}){
  return (
   <styled.AvatarWrapper>
      <img src={avatar} alt="avatar" width={130}/>
      <styled.UserNameWrapper>
        <styled.HelloTitle>Привіт,</styled.HelloTitle>
        <styled.UserName>{userName}</styled.UserName>
      </styled.UserNameWrapper>
     
   </styled.AvatarWrapper>
  )
};

export default AvatarBlock;
