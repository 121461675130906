import styled from "styled-components";


export const List = styled.ul`
 display: flex;
   flex-wrap: ${(props) => (props.isAllAdmin ? "" : "wrap")};
    gap: ${(props) => (props.isAllAdmin ? "" : "25px")};

`
export const AddTrialItem = styled.li`
   position: relative;
   padding: 35px 0px;
   background: #F1F1F1;
   box-shadow: 0px 4px 4px 0px #00000040;
   ${(props) =>
      !props.isAllAdmin
        ? `
        flex-basis: calc(100% / 3 - 25px);
        
        @media (min-width: 768px) {
           flex-basis: calc(100% / 4 - 25px);
        }
        `
        : `
        &:not(:last-child) {
           margin-bottom: 20px;
        }
        `}


`
export const TrialItem = styled(AddTrialItem)`
   background-color: #A09EFF;
`

export const ItemTitle = styled.p`

   font-family: Raleway;
   font-size: 18px;
   font-weight: 700;
   line-height: 24.2px;

   text-align: center;
`

export const ItemTitleTeacher = styled(ItemTitle)`
   font-size: 15px;
   font-weight: 400;
   margin-top: 10px;
`

export const RemoveInTeacher = styled.span`
   position: absolute;
   font-family: Raleway;
   font-size: 12px;
   font-weight: 700;
   top: 10px;
   left: 10px;
   cursor: pointer;

   &:hover{
      color: red;
   }
`

export const Column = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin: 0px 20px 20px 0px;
  }
`;
export const ColumnTitle = styled.p``;
