import * as styled from "./OurStudents.styled.jsx";
import { ageGroups } from "../../../helpers/ageGroups.js";
import { useNavigate } from "react-router-dom";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled.jsx";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle.jsx";

function OurStudents({adminInfo}) {
  const navigate = useNavigate()

  const groupItemClick = (groupItem) => {
    navigate(`${groupItem}`)
  }
  return (
    <PagesWrapper>
      <TopPageTitle value={"Наші учні"} />
      <styled.GroupList>
        {ageGroups.map(({name, value}) => (
            <styled.GroupItem key={value} onClick={() => groupItemClick(value)}>
                <styled.GroupTitle>{name}</styled.GroupTitle>
            </styled.GroupItem>
        ))}
        
      </styled.GroupList>
    </PagesWrapper>
  );
}

export default OurStudents;
