import { useForm } from "react-hook-form";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle.jsx";
import axios from "../../../axios";
import { teacherTextFields } from "../../../helpers/teacherTextFields.js";
import * as styled from "./AddTeacher.styled.jsx";
import { useEffect, useState } from "react";
import { CommonBtn } from "../../../styles/components/Button.styled.jsx";
import TextInput from "../../../components/TextInput/TextInput.jsx";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled.jsx";

function AddTeacher({adminInfo}) {
  const [successAdded, setSuccessAdded] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({
    defaultValues: {
      name: "",
      teacherId: "",
      students: [],
      phoneNumber: "",
      email: "",
      password: "",
      googleMeetLink: "",
      salary: [],
      userType: "teacher",
    },
    mode: "onChange",
  });
  const onSubmit = async (data) => {
    try {
      const {
        name,
        teacherId,
        students,
        phoneNumber,
        email,
        password,
        googleMeetLink,
        userType,
        salaryKids,
        salaryTeenagers,
        salaryAdults,
      } = data;
      const salary = [
        { name: "kids", value: Number(salaryKids) },
        { name: "teenagers", value: Number(salaryTeenagers) },
        { name: "adults", value: Number(salaryAdults) },
      ];
      const accessType = adminInfo.accessType
      await axios.post(
        "teachers",
        {name,
        teacherId,
        students,
        phoneNumber,
        email,
        password,
        googleMeetLink,
        userType,
        salary,
        accessType
      }
      );
      setSuccessAdded(true);
      reset();
    } catch (error) {
      setSuccessAdded(false);
    }
  };
  
  return (
    <PagesWrapper>
      <TopPageTitle value={"Додати вчителя"} />

      {successAdded === null && (
        <styled.Form onSubmit={handleSubmit(onSubmit)}>
          {teacherTextFields.map(({ name, inputName }) => (
            <TextInput
              key={name}
              fieldName={inputName}
              name={name}
              register={register}
              errors={errors}
            />
          ))}
          <TextInput
            name={"salaryKids"}
            fieldName={"ЗП за один урок з дітьми"}
            register={register}
            errors={errors}
          />
          <TextInput
            name={"salaryTeenagers"}
            fieldName={"ЗП за один урок з підлітками"}
            register={register}
            errors={errors}
          />
          <TextInput
            name={"salaryAdults"}
            fieldName={"ЗП за один урок з дорослими"}
            register={register}
            errors={errors}
          />

          <CommonBtn type="submit">Додати</CommonBtn>
        </styled.Form>
      )}

      {successAdded === false && <p>Add teacher was failed</p>}
      {successAdded === true && <p>Added teacher successfully</p>}
    </PagesWrapper>
  );
}

export default AddTeacher;
