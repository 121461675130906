export const trialLessons = [
   {
     type: "en",
     lesson: [
       {
         name: "kids",
         url: "https://www.canva.com/design/DAGOSuI6pZs/bvLCybHhiM2w_12Isu_wuA/view?utm_content=DAGOSuI6pZs&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
       {
         name: "teenagers",
         url: "https://www.canva.com/design/DAGB7gy39yo/SSWqGAI-YcTjs1iijsWlFw/view?utm_content=DAGB7gy39yo&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
       {
         name: "adults",
         url: "https://www.canva.com/design/DAGCBJSLUFY/xJoQxxOy8WkFoo9NM-mNIw/view?utm_content=DAGCBJSLUFY&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
     ],
   },
   {
     type: "de",
     lesson: [
       {
         name: "kids",
         url: "https://www.canva.com/design/DAGOSuI6pZs/bvLCybHhiM2w_12Isu_wuA/view?utm_content=DAGOSuI6pZs&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
       {
         name: "teenagers",
         url: "https://www.canva.com/design/DAGB7gy39yo/SSWqGAI-YcTjs1iijsWlFw/view?utm_content=DAGB7gy39yo&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
       {
         name: "adults",
         url: "https://www.canva.com/design/DAGCBJSLUFY/xJoQxxOy8WkFoo9NM-mNIw/view?utm_content=DAGCBJSLUFY&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
     ],
   },
   {
     type: "pl",
     lesson: [
       {
         name: "kids",
         url: "https://www.canva.com/design/DAGOSuI6pZs/bvLCybHhiM2w_12Isu_wuA/view?utm_content=DAGOSuI6pZs&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
       {
         name: "teenagers",
         url: "https://www.canva.com/design/DAGB7gy39yo/SSWqGAI-YcTjs1iijsWlFw/view?utm_content=DAGB7gy39yo&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
       {
         name: "adults",
         url: "https://www.canva.com/design/DAGCBJSLUFY/xJoQxxOy8WkFoo9NM-mNIw/view?utm_content=DAGCBJSLUFY&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
     ],
   },
 ];